<template>
  <section
    class="page-container"
    :class="{
      'shop-player-not-active': !shopPlayerFirstClick,
      'with-player': isShowShopPlayer,
    }"
  >
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <loader v-if="loading" />

    <shop-player
      v-if="isPlayerEnabled"
      v-show="isShowShopPlayer"
      ref="shopPlayer"
      :class="[shopPlayerTagClasses]"
      :items="shopPlayerItems"
      :is-loading="shopPlayerItemsLoading"
      :scroll-navigate="false"
      :cropped-mode="!shopPlayerFirstClick"
      :full-page-mode="shopPlayerFullPageMode"
      :picture-in-picture="!!floatItem"
      :shop-controls="shopPlayerControls"
      :active-item="shopPlayerActiveItem"
      :items-products="itemsProducts"
      :items-stats="itemsStats"
      :items-overlay-state="itemsOverlaysState"
      :items-channel-follow-state="itemsChannelsFollowState"
      :chat-config="chatConfig"
      :use-high-initial-bitrate="true"
      :hot-keys="isHotKeysEnabled"
      :loop-tabs="true"
      :rewind="true"
      :autoplay="!floatItem && !popupItem"
      :loop="activeTab && activeTab.contentType === 'vod'"
      :logo-link="logoLink || require('src/assets/logo/logo-vimmi-white.svg')"
      :background="'purple'"
      :tabs="activeTabs"
      :active-tab="activeTab"
      :is-store-name-clickable="false"
      :viewers-limit-reached="viewersLimitReached"
      :load-tab-items="loadTabItems"
      :get-playback-url="getPlaybackUrl"
      :can-play="canPlay"
      @select:tab="selectTab"
      @playback-state="shopPlayerPlaybackStateUpdated($event)"
      @area-click="shopPlayerAreaClick"
      @play-btn-click="shopPlayClick"
      @fs-state="shopPlayerFullScreenChanged"
      @active-item="setShopPlayerActiveItem($event)"
      @open-pip="openFloatPlayer($event)"
      @toggle-follow="toggleFollow($event)"
      @block-scroll="blockPageScroll($event)"
      @unblock-scroll="unBlockPageScroll($event)"
      @chat-user-updated="chatUserUpdated($event)"
      @product-click="onProductClick($event)"
      @close-product="closeProductsDetailsPopup($event)"
      @send-reaction="sendReaction($event)"
      @share="openSharePopup($event)"
      @title-click="openStorePage($event, true, true)"
      @need-login="showLoginPopup($event)"
      @player:stats="shopPlayerStats($event, 'main')"
    >
      <template #products-details-popup>
        <product-details-popup-component
          v-if="productPopupDetails"
          :product="productPopupDetails"
          @close="closeProductsDetailsPopup"
        />
      </template>
    </shop-player>

    <div v-if="!loading" class="areas-container">
      <span v-if="!isNotEmpty && isLivePage" class="page-title"> Live </span>
      <areas
        :areas="areas"
        :storefront-id="storefrontId"
        :page="headerPageType"
        :profile="channelProfile"
        :badges="badges"
        :provider-id="providerId"
      />

      <div
        v-if="head.creator"
        class="yotpo yotpo-main-widget"
        :data-product-id="head.creator.id"
        :data-name="head.creator.name"
        :data-image-url="metadata.image"
        :data-url="metadata.url"
      />
      <search-error
        v-if="!isNotEmpty && isLivePage"
        :problem-text="'Sorry, there are no live shows to display. Please check back later.'"
      />
    </div>

    <b-modal ref="error_modal" :title="modalTitle" hide-footer>
      <span v-t="'MODAL.PAGE.CONTENT'" />
    </b-modal>

  </section>
</template>

<script>
  import Areas from '@/components/areas/areas';
  import Loader from '@/components/loader/loader';
  import JsonLD from '@/service/jsonld';
  import { escapeStringJS } from '@/utils/escape';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { AuthVariableMixin, ScreenReadyMixin } from '@/plugins/mixin';
  import { getMetaIos } from '@/utils/generateDeepLink';
  import { amsClient } from '@/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import rtm from '@/service/realTimeMessages';
  import { mapActions, mapState } from 'vuex';
  import ErrorPage from '../ErrorPage/ErrorPage';
  import SearchError from '@/components/searchError/searchError';
  import badgesFilter from 'src/filters/expiredBadgesFilter';
  import device from '@/service/device-service';
  import { debounce } from 'lodash';
  import FloatPlayerMixin from '@/shared/mixins/float-player.mixin';
  import ProductDetailsPopupComponent from '@/shared/components/shop-player/product-details-popup.component.vue';
  import { ShopPlayer } from 'shop-player-v2/dist/shop-player-v2.cjs';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import ShoprzStore from '@/store/shoprz';

  import Vue from 'vue';
  import smoothScroll from '@/shared/helpers/smooth-scroll';
  import ShopPlayerService from '@/shared/services/shop-player.service';
  import slugify from 'slugify';
  import RecommendationsApi from '@/shared/api/recommendations';
  import { makeId } from 'vimmi-web-utils/cjs/makeId';

  export default {
    name: 'PageContainer',
    components: {
      ShopPlayer,
      ProductDetailsPopupComponent,
      SearchError,
      ErrorPage,
      Areas,
      Loader,
    },
    mixins: [AuthVariableMixin, ScreenReadyMixin, FloatPlayerMixin, ShopPlayerMixin],

    data() {
      return {
        loading: true,
        providerId: null,
        areas: [],
        subscribers: [],
        content: null,
        head: {},
        metadata: {},
        channelProfile: null,
        currentChannel: null,
        isNotEmpty: true,
        prevTitle: '',
        shopPlayerItemsLoading: true,
        shopPlayerFullPageMode: true,
        shopPlayerFirstClick: !!device.isAnyMobile(),
        shopPlayerActiveItem: null,
        shopPlayerPlaybackState: {},
        isScrollFloatPlayerEnable: false,
        storefrontId: '',
        tabs: [],
        tabsItems: {},
        activeTab: null,
      };
    },
    computed: {
      ...mapState('popupPlayer', {
        vodPopupItem: 'item',
        vodPopupPlaybackState: 'playbackState',
      }),
      isShowShopPlayer() {
        return !this.loading && this.isPlayerEnabled && this.activeTabs.length;
      },
      isHotKeysEnabled() {
        return !!!this.vodPopupItem;
      },
      isPlayerEnabled() {
        return (
          this.playerSegment?.player_enabled && this.$route.meta.creatorPage
        );
      },
      shopPlayerItems() {
        let items = [];

        if (!this.activeTab) {
          return items;
        }

        items = this.tabsItems[this.activeTab.id] || [];

        items.forEach((element) => {
          this.$set(
            this.itemsChannelsFollowState,
            element.id,
            ShoprzStore.isChannelFollowed(
              element?.creator?.id || element?.storefront?.id,
            ),
          );
        });

        return items;
      },
      isLivePage() {
        return this.$route.path === '/screen/live/';
      },
      headerPageType() {
        return 'section';
      },
      modalTitle() {
        return this.$t('MODAL.PAGE.TITLE');
      },
      isLiveRoute() {
        return this.$route.path === '/screen/live/';
      },
      seoTitle() {
        return this.head.creator.name;
      },
      seoDescription() {
        return this.head.creator.description.replaceAll(/<[^>]*>/g, '');
      },
      seoImage() {
        return this.head.creator.picture;
      },
      badges() {
        return badgesFilter(this.head.creator?.badges);
      },
      isShopMenu() {
        return this.$store.getters['shopMenu/isShopMenu'];
      },
      getMenuList() {
        return this.$store.getters['shopMenu/getMenuList'];
      },
      // isShopPlayerEnabled() {
      //   return this.head?.player_enabled === undefined || this.head?.player_enabled; // by default should be shown
      // },
      shopPlayerControls() {
        return {
          pip: !this.floatItem,
          volume: !this.floatItem,
          fullscreen: !this.floatItem,
          productsDrawer: !this.shopPlayerActiveItem?.vod_short && !this.floatItem,
          upDownToggler: !this.floatItem,
          chat: !!this.shopPlayerFirstClick && !this.floatItem,
          tabs: !this.floatItem,
        };
      },
      playerSegment() {
        return this.areas.find((s) => s.itype === 'screen_playertabs_shoprz');
      },
      // INFO activeTabs => tabs with videos or lives!!!
      activeTabs() {
        return this.tabs.filter((tab) => {
          // (tab.contentType === 'live' || (tab.contentType !== 'live' && tab.link))
          return tab.link && this.tabsItems[tab.id].length;
        });
      },
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
    },
    watch: {
      loading(val) {
        if (val === false) {
          this.setScreenReady(this.head, this.screenRequestTime);
        }
      },
      isShowShopPlayer: {
        handler(state) {
          if (state) {
            this.$bus.$emit(
              'handleSpecificClass',
              this.shopPlayerSpecialClasses,
            );
            this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
            // this.isScrollFloatPlayerEnable = true;
          } else {
            this.$bus.$emit('handleSpecificClass', '');
            this.$bus.$emit('handleHeaderSpecificClass', '');
            // this.isScrollFloatPlayerEnable = false;
          }
        },
        immediate: true,
      },
      // vodPopupPlaybackState(val) {
      //   if (this.$refs.shopPlayer && this.shopPlayerActiveItem) {
      //     if (!val.paused && !this.shopPlayerPlaybackState.paused) {
      //       this.$refs.shopPlayer.pause(null, false);
      //     }
      //   }
      // },
    },
    mounted() {
    },
    created() {
      this.$bus.$on('segmentIsNotEmpty', this.setIsNotEmpty);
      document.addEventListener('scroll', this.onPageScroll);
      // this.$bus.$on('logout', () => {
      //   console.log(1111111111, 'LOGOUT');
      //   this.loading = true;
      //   this.cleanPlayerState();
      //   rtm.off(this.subscribers);
      //   this.$nextTick(() => {
      //     this.getData(this.$route);
      //   });
      // });
      // this.$bus.$on('amsLogged', () => {
      //   console.log(1111111111, 'amsLogged');
      //   this.loading = true;
      //   this.cleanPlayerState();
      //   rtm.off(this.subscribers);
      //   this.$nextTick(() => {
      //     this.getData(this.$route);
      //   });
      // });
      this.$bus.$on('changedUserState', this.onAuthStateChanged);
    },
    beforeDestroy() {
      document.removeEventListener('scroll', this.onPageScroll);
      this.$bus.$emit('handleSpecificClass', '');
      this.$bus.$emit('handleHeaderSpecificClass', '');

      this.$bus.$off('segmentIsNotEmpty', this.setIsNotEmpty);
      this.$bus.$off('changedUserState', this.onAuthStateChanged);
      this.cleanPlayerState();
      rtm.off(this.subscribers);

      JsonLD.pop();
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.cleanPlayerState();
        rtm.off(vm.subscribers);
        vm.loading = true;
        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      JsonLD.pop();
      this.loading = true;
      this.cleanPlayerState();
      rtm.off(this.subscribers);
      this.$nextTick(() => {
        this.getData(to);
        next();
      });
    },
    methods: {
      ...mapActions({
        setTemplate: 'chachedPreviewMedia/setPlayTemplate',
      }),

      async onChangedUserState() {
        console.log('onChangedUserState page');
        if (this.floatItem && !this.canPlay(this.floatItem)) {
          this.hideFloatPlayer();
        }
        if (this.shopPlayerActiveItem && !this.canPlay(this.shopPlayerActiveItem)) {
          this.shopPlayerActiveItem = { ...this.shopPlayerActiveItem };
        }
      },
      async fetchPlayerTabsAndContents(playerSegment) {
        try {
          const tabsRes = await amsClient.callAms(playerSegment.link);
          this.tabs = tabsRes.items
            .filter((i) => i.link)
            .map((tab) => {
              const type = tab.link.match(/\/on_air\/.*/) ? 'live' : 'vod';
              const id = tab.id || slugify(tab.title);
              this.$set(this.tabsItems, id, []);
              return {
                id: id,
                title: tab.title,
                link: tab.link,
                count: tab.count || tab.length || 0,
                loaded: false,
                contentType: type,
                is_published: tab.is_published,
              };
            })
            .sort((tab) => {
              return tab.contentType === 'live' ? -1 : 0;
            });
          await Promise.all(
            this.tabs
              .filter((tab) => tab.link)
              .map((tab) => {
                return this.loadTabItems(tab, true);
              }),
          );
        } catch (err) {
          console.log(err);
        }
      },
      async selectTab({ tab, lite }) {
        if (lite) {
          this.activeTab = { ...tab };
        } else {
          this.shopPlayerItemsLoading = true;
          // this.shopPlayerActiveItem = null;
          this.activeTab = { ...tab };
          await this.loadTabItems(tab);

          const nextActiveItem = this.tabsItems[tab.id][0];
          await this.setShopPlayerActiveItem(nextActiveItem);

          this.shopPlayerAreaClick();
          setTimeout(() => {
            this.shopPlayerItemsLoading = false;
          }, 200);
        }
      },

      async loadTabItems(tab, force = false) {
        if (this.tabsItems[tab.id].length && !force) {
          return this.tabsItems[tab.id];
        }
        if (tab.loaded && !force) {
          return this.tabsItems[tab.id];
        }
        const items = await this.loadShopPlayerItems(tab.contentType, tab);
        this.$set(
          this.tabsItems,
          tab.id,
          items.filter((item) => {
            return (
              item.on_air ||
              item.event_id ||
              (item.is_published && item.media_state === 'online')
            );
          }),
        );
        const tabIndex = this.tabs.findIndex((item) => item.id === tab.id);
        tab.count = this.tabsItems[tab.id].length;
        tab.loaded = true;
        this.$set(this.tabs, tabIndex, { ...tab });
        return this.tabsItems[tab.id];
      },

      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Vue & ShopPlayer}
       * */
      getShopPlayerRef() {
        return this.$refs.shopPlayer;
      },
      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Object}
       * */
      getActiveItem() {
        return this.shopPlayerActiveItem;
      },
      onPageScroll(event) {
        if (this.isShowShopPlayer) {
          if (document.scrollingElement?.scrollTop) {
            this.$bus.$emit('handleHeaderSpecificClass', '');
          } else {
            this.$bus.$emit('handleHeaderSpecificClass', 'shop-player-header');
          }
        }
      },
      updateTitle(title) {
        if (!title) {
          return;
        }
        this.$bus.$emit('updateTitle', title);
      },
      fillHeader() {
        const isFilledMetadata = !!Object.keys(this.metadata).length;

        if (!isFilledMetadata) {
          this.metadata = {
            ...normaliseMetaTags(this.head),
            image: this.head.creator?.picture,
            description: this.head.creator?.description,
            url: `${location.origin}/s/${this.head.creator?.slug}`,
          };
        }

        this.metadata = {
          ...normaliseMetaTags(this.head),
          ...this.metadata,
        };

        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': { content: 200 },
          'meta[name="apple-itunes-app"]': { content: getMetaIos(this.head) },
        });

        this.prevTitle = this.metadata.title;
      },
      listenRTM() {
        this.subscribers = rtm.on(
          ['started', 'on_air_now', 'finished'],
          debounce(async (data) => {
            const liveTab = this.tabs.find((tab) => tab.contentType === 'live');

            if (data.op === 'finished') {
              setTimeout(() => {
                if (this.activeTab?.contentType === 'live') {
                  const index = this.tabsItems[this.activeTab.id].findIndex(
                    (liveItem) => liveItem.id === data.room,
                  );
                  const finishedItem = this.tabsItems[this.activeTab.id][index];
                  this.$set(
                    this.tabsItems,
                    this.activeTab.id,
                    this.tabsItems[this.activeTab.id].filter(
                      (liveItem) => liveItem.id !== data.room,
                    ),
                  );

                  if (!this.tabsItems[this.activeTab.id].length) {
                    if (this.activeTabs.length) {
                      this.selectTab({ tab: this.activeTabs[0] });
                    } else {
                      this.connectLobby();
                    }
                    // todo: update live tab with count=0
                  } else if (
                    finishedItem?.id === this.shopPlayerActiveItem?.id
                  ) {
                    if (this.floatItem?.id === this.shopPlayerActiveItem.id) {
                      /** todo: play next/prev live in float player or just hide it */
                      this.hideFloatPlayer();
                    }

                    if (finishedItem) { // todo: temporary for google analytics, need to refactor
                      let pPercents = this.positionPercent[this.shopPlayerActiveItem.id] || [];
                      if (pPercents[pPercents.length - 1] !== 1) {
                        this.sendProgressEvent(this.shopPlayerActiveItem, this.shopPlayerPlaybackState, 100);
                        pPercents[pPercents.length - 1] = 1;
                        this.positionPercent[this.shopPlayerActiveItem.id] = pPercents;
                      }
                    }

                    this.setShopPlayerActiveItem(
                      this.tabsItems[this.activeTab.id][index] || this.tabsItems[this.activeTab.id][index - 1],
                    );
                  }
                } else {
                  const index = this.tabsItems[liveTab.id].findIndex(
                    (liveItem) => liveItem.id === data.room,
                  );
                  const finishedItem = this.tabsItems[liveTab.id][index];
                  this.$set(
                    this.tabsItems,
                    liveTab.id,
                    this.tabsItems[liveTab.id].filter(
                      (liveItem) => liveItem.id !== data.room,
                    ),
                  );
                }
              }, 8000); // todo: timeout
            } else if (data.op === 'started' || data.op === 'on_air_now') {
              if (liveTab) {
                const latestItems = await this.loadTabItems(liveTab, true);

                if (!latestItems.length) {
                  return;
                }

                if (!this.tabsItems[liveTab.id].length) {
                  this.$set(this.tabsItems, liveTab.id, latestItems);
                } else {
                  const newItem = latestItems.find((item) => {
                    return item.id === data.room;
                  });

                  const existItem = this.tabsItems[liveTab.id].find((item) => {
                    return item.id === data.room;
                  });

                  if (newItem && !existItem) {
                    this.$set(this.tabsItems, liveTab.id, [
                      ...this.tabsItems[liveTab.id],
                      newItem,
                    ]);
                  }
                }

                if (
                  this.activeTabs.length === 1 &&
                  this.activeTabs[0].contentType === 'live'
                ) {
                  // this.activeTab = liveTab;
                  this.selectTab({ tab: liveTab });
                }
              }
            }
          }, 1000),
        );
      },
      addSchemaOrg(item) {
        const breadcrump = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': `${window.location.origin}/`,
                name: 'PROTV PLUS',
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                '@id': `${window.location.origin}/${item.link}`,
                name: escapeStringJS(item.title),
              },
            },
          ],
        };

        JsonLD.push(breadcrump);
        JsonLD.update();
      },
      setIsNotEmpty(payload) {
        this.isNotEmpty = payload;
      },
      onAuthStateChanged({ type }) {
        // 'logout' - do not listen to the event, because after 'logout' will be triggered 'logged' for the quest user
        if (type === 'logged') {
          this.cleanPlayerState();
          rtm.off(this.subscribers);
          this.$nextTick(() => {
            this.getData(this.$route);
          });
        }
        if (type === 'logout') {
          this.loading = true;
        }
      },
      async getData(route) {
        if (route?.meta?.productPage) {
          try {
            const { head } = await amsClient.callAms(
              `/shoprzapi/product/${route.params.id}`,
            );

            this.$router.replace({ path: head.page });
          } catch {
            this.$router.replace({ path: '/error' });
          }
          return;
        }

        const storeSlug =
          this.$isStandaloneStore && !location.pathname.includes('/s/')
            ? this.$standaloneStoreName
            : route.params.id;

        let url = '';

        if (this.isShopMenu && !location.pathname.includes('/s/')) {
          url = this.getMenuList[0].link;
        } else {
          url = `/screen/${storeSlug}/`;
        }

        console.log('url: ', url, this.isShopMenu);

        this.screenRequestTime = this.setScreenRequest({
          id: storeSlug,
          itype: 'screen',
          link: url,
        });

        try {
          this.areas = [];
          const data = await amsClient.callAms(url);
          this.setCurrentScreen(data.head);
          this.head = {
            // ...this.head,
            ...data.head,
          };

          this.$stats.send(
            'page_opened',
            {
              page_type: 'screen',
              // channel_id: this.$stats.getChannelId(this.head),
              // store_id: this.$stats.getStoreId(this.head),
            },
            { item: this.head },
          );

          this.currentChannel = data.head;
          // console.log('this.currentChannel', this.currentChannel);
          // console.log('this.$route.meta', data);

          if (this.$route.path.includes('/l/')) {
            this.handleOpenLivePopup();
          }

          if (this.$route.meta.seoPage) {
            this.handleSeoPage();
          }

          this.content = data.content;
          let items = [...data.items];

          const isChannel =
            data.head?.storefront?.storefront_type === 'channel';

          if (isChannel) {
            this.storefrontId = data.head?.storefront?.id;
          }

          if (this.$isStandaloneStore && location.pathname.includes('/s/')) {
            document.title =
              data.head?.storefront?.name || data.head?.title || 'Title';
            if (isChannel) {
              const itemsLen = items.length;
              items = items.filter((section) => {
                return section.itype === 'banner_group'
                  ? !!section.items.length
                  : true;
              });

              let tarIndex =
                items[0] && items[0].itype === 'banner_group'
                  ? itemsLen > 1
                    ? 1
                    : 0
                  : 0;
              // if (itemsLen >= 2) {
              //   if (items[0].items && !items[0].items.length) {
              //     tarIndex = itemsLen >= 2 ? 2 : tarIndex;
              //   }
              // }
              items.splice(tarIndex, 0, {
                storefront: data.head?.storefront,
                storefront_type: data.head?.storefront?.storefront_type,
                itype: 'custom_channel_profile',
                title: data.head?.storefront?.name,
                creator: {
                  picture: data.head?.storefront?.picture,
                  name: data.head?.storefront?.name,
                  description: data.head?.storefront?.description,
                },
              });
            }
          }

          data.head.cached = !!data.cached;
          this.head = data.head;
          this.setScreenResponse(this.head);
          this.fillHeader();
          this.addSchemaOrg(this.head);

          if (memoryStore.get('redirect')) {
            const err = memoryStore.get('redirect');
            this.showErrorModal(err.err, err.code);
            memoryStore.set('redirect', null);
          } else {
            this.showErrorModal(data.error);
          }

          this.providerId = data.head?.creator?.provider_id || null;

          this.areas = items;

          console.log('items: ', this.areas);

          // const storefront = items.find(item => item.itype === 'custom_channel_profile');
          // console.log('DATA ITEMS', this.areas);

          // if (this.$route.meta.creatorPage && this.isShopPlayerEnabled) {
          //   this.initShopPlayer();
          // }
          if (this.isPlayerEnabled) {
            await this.initShopPlayer();
          }

          this.loading = false;
          this.setTemplate();
          // console.log('this.shopPlayerItems: ', this.shopPlayerItems);
          // this.$set(
          //   this.itemsChannelsFollowState,
          //   itemId,
          //   ShoprzStore.isChannelFollowed(storefront.id),
          // );
        } catch (e) {
          this.$plError.setError(e, 'network');
          this.$router.replace({ path: '/error' });
        }
      },

      async initShopPlayer() {
        this.shopPlayerItemsLoading = true;
        // todo: prevent double player initialization
        await this.fetchPlayerTabsAndContents(this.playerSegment);
        this.$nextTick(async () => {

          /** if returned from float player */
          let floatItem = null;
          let floatItemId = null;
          let floatItemTab = null;

          if (
            this.floatOptions.type !== 'vod-popup' &&
            this.floatOptions.openerPage === this.$route.fullPath
          ) {
            floatItem = this.floatOptions.item;
            floatItemId = this.floatOptions.itemId;
            floatItemTab = this.floatOptions.tab;
            this.setFloatItem(null);
            this.setFloatOptions(null);
          }

          const liveTab = this.tabs.find((tab) => tab.contentType === 'live'); // assumed that on page will be only one live tab
          const vodTab = this.tabs.find((tab) => tab.contentType === 'vod'); // any first vod tab

          // todo: currently all tabs and items loaded in the fetchPlayerTabsAndContents
          // if (liveTab) {
          //   await this.loadTabItems(liveTab);
          // }

          let targetItem;
          let activeFloatItem;

          if (floatItemId && floatItemTab) {
            this.activeTab = floatItemTab;

            // todo: currently all tabs and items loaded in the fetchPlayerTabsAndContents
            // if (this.tabsItems[floatItemTab.id]?.length) {
            //   await this.loadTabItems(floatItemTab);
            // }

            activeFloatItem = this.tabsItems[floatItemTab.id].find(
              (item) => floatItemId === item.id,
            );
            // if the list refreshed
            if (!activeFloatItem && floatItem) {
              this.$set(this.tabsItems, floatItemTab.id, [
                floatItem,
                ...this.tabsItems[floatItemTab.id],
              ]);
              activeFloatItem = floatItem;
            }
            targetItem = activeFloatItem;
          }

          if (!targetItem && liveTab) {
            targetItem = this.tabsItems[liveTab.id][0];

            if (targetItem) {
              this.activeTab = liveTab;
            }
          }
          if (!targetItem && vodTab) {
            // todo: currently all tabs and items loaded in the fetchPlayerTabsAndContents
            // await this.loadTabItems(vodTab);
            targetItem = this.tabsItems[vodTab.id][0];

            if (targetItem) {
              this.activeTab = vodTab;
            }
          }
          if (targetItem) {
            await this.setShopPlayerActiveItem(targetItem);
            if (activeFloatItem) {
              this.applyFloatStateAfterInitShopPlayer(floatItemId);
            }
          }

          this.$nextTick(() => {
            this.shopPlayerItemsLoading = false;
          });

          if (!this.vodPopupItem) {
            this.$nextTick(() => {
              smoothScroll(document.documentElement, {
                top: 0,
                duration: 100,
              });
            });
          }
        });
        this.listenRTM();
      },
      async loadShopPlayerItems(type, tab) {
        let items = [];
        const query = {};
        if (type === 'live') {
          if (this.$route.name !== 'Home' && Vue.prototype.$isStandaloneStore) {
            if (this.head?.storefront?.storefront_type === 'channel') {
              query.target_id = this.head?.storefront?.id;
              if (!query.target_id) {
                return [];
              }
            }
          }
          try {
            const sectionData = await amsClient.callAms('/on_air/', {
              cache: false,
              query,
            });
            items = sectionData.items;
          } catch (e) {
            console.warn(e);
          }
        } else if (type === 'vod' && tab) {
          try {
            const store = amsClient.get('conf.store');
            if (store?.provider_id) {
              query.provider_id = store?.provider_id;

              const isChannelPage = ['ChannelPage', 'ChannelSharePage'].includes(this.$route.name);
              if (this.head?.storefront?.storefront_type === 'channel' && isChannelPage) {
                query.target_id = this.head?.storefront?.id;
              }
            } else {
              return [];
            }

            if (tab.link.indexOf('recommendation') !== -1) {
              items = await RecommendationsApi.getVODRecommendations(query);
            } else {
              const data = await amsClient.callAms(tab.link);
              items = data.items;
            }
          } catch (e) {
            console.warn(e);
          }
        }
        await this.onPlayTemplateReady();
        return items.map((item) => {
          const playbackUrl = ShopPlayerService.generatePlaybackUrl(
            item,
            this.getPlayTemplate,
          );
          if (playbackUrl) {
            item.playback_url = playbackUrl;
          }
          item.uniq_id = this.generateUniqIdForPlayer(item);
          item.tab_id = tab.id;
          return item;
        });
      },
      async shopPlayClick($event) {
        this.shopPlayerAreaClick({ ...$event, play: true });
      },
      async shopPlayerAreaClick($event) {
        if (this.floatItem && $event?.play) {
          if (
            this.floatItem.id === this.shopPlayerActiveItem?.id &&
            this.floatOptions.type !== 'vod-popup'
          ) {
            this.applyFloatPlayerStateToMain(null, true);
          }
          this.hideFloatPlayer();
        } else if (
          !device.isAnyMobile() &&
          !this.shopPlayerFirstClick &&
          this.shopPlayerPlaybackState.muted
        ) {
          if (this.$refs.shopPlayer) {
            this.$refs.shopPlayer.unmute();
          }
        }
        this.shopPlayerFirstClick = true;
        this.$bus.$emit('handleSpecificClass', this.shopPlayerSpecialClasses);
      },
      shopPlayerPlaybackStateUpdated({ itemId, state }) {
        if (itemId === this.shopPlayerActiveItem?.id) {
          this.shopPlayerPlaybackState = state;
        }
      },
      async setShopPlayerActiveItem(item) {
        if (!item.chat_id) {
          if (this.loadingItemId === item.uniq_id) {
            return;
          }
          this.loadingItemId = item.uniq_id;

          const fullItem = item.chat_id ? item : await this.loadFullItem(item);

          if (this.loadingItemId === fullItem.uniq_id) {
            this.shopPlayerActiveItem = fullItem;
          }
        } else {
          this.shopPlayerActiveItem = item;
        }
        console.log('[setShopPlayerActiveItem]', item.uniq_id, { ...item });
      },
      showErrorModal(error_modal = null, code = null, redirect = null) {
        if (error_modal) {
          this.$refs.error_modal.show();
          code = 301;

          memoryStore.set('autoplay', false);
          memoryStore.set('close_backdrop', true);
        } else {
          memoryStore.set('autoplay', true);
        }
        const res = code ? { content: `${code}` } : { content: '200' };
        this.metadata.head = Object.assign(this.metadata.head, {
          'meta[name="prerender-status-code"]': res,
        });
      },
      async handleSeoPage() {
        const { path } = this.$route;
        const productMatch = Array.from(
          path.matchAll(/^\/s\/(.*)\/pc\/(.*)\/p\/(.*)/gi),
        );
        const vodMatch = Array.from(path.matchAll(/^\/s\/(.*)\/v\/(.*)/gi));
        const comingLiveMatch = Array.from(
          path.matchAll(/^\/s\/(.*)\/l\/(.*)/gi),
        );
        if (productMatch.length) {
          const product = productMatch[0][3];

          const { head } = await amsClient.callAms(
            `/shoprzapi/product/${product}`,
          );

          this.updatePageMetadata(head);

          this.$bus.$emit('toggleProductPopup', { item: head });
        } else if (vodMatch.length) {
          const vodPath = vodMatch[0][0];

          const { head } = await amsClient.callAms(vodPath);

          head.chat_id = head.event?.chat_id || '';
          head.chat_url = head.event?.chat_url || '';

          this.updatePageMetadata(head);
          // if (!this.vodPopupItem || this.vodPopupItem.id !== head.id) {
          // this.$router.push(`/v/${head.slug || head.id}`);
          // this.$bus.$emit('togglePopupVOD', { item: head });
          // }
        } else if (comingLiveMatch.length) {
          this.handleOpenLivePopup();
        }

        window.prerenderReady = true;
      },
      async handleOpenLivePopup() {
        const { path } = this.$route;
        const comingLiveMatch = Array.from(
          path.matchAll(/^\/s\/(.*)\/l\/(.*)/gi),
        );
        if (comingLiveMatch.length) {
          const livePath = comingLiveMatch[0][0];

          const { head } = await amsClient.callAms(livePath);

          if (head.status === 'ready') {
            // this.$router.push(`/s/${this.currentChannel.creator.slug}`);
            return;
          }
          if (head.status === 'finished') {
            // this.$router.push(`/s/${this.currentChannel.creator.slug}`);

            // this.$bvModal.msgBoxOk('That Live is finished', {
            //   centered: true,
            //   size: 'confirm',
            //   okOnly: true,
            // });

            this.$bus.$emit('openLiveFinishedPopup');

            return;
          }

          this.updatePageMetadata(head);

          this.$bus.$emit('ToggleScheduleEventDialog', {
            ...head,
            creator: this.currentChannel.creator || this.currentChannel.storefront,
          });
        }
      },
      updatePageMetadata(item) {
        this.metadata = {
          ...this.metadata,
          title: item.title,
          description: item.description,
          image: item.backdrop || item.poster || item.cover,
          url: `${this.$location}${item.share}`,
        };
      },
      cleanPlayerState() {
        this.shopPlayerItemsLoading = true;
        // this.shopPlayerVodSection = null;
        // this.shopPlayerVodItems = [];
        // this.shopPlayerLiveItems = [];
        this.tabsItems = {};
        this.tabs = [];
        // this.shopPlayerActiveType = null;
        this.shopPlayerActiveItem = null;
        // this.shopPlayerPlayStateBeforePopup = null;
        this.shopPlayerPlaybackState = {};
        this.itemsChannelsFollowState = {};
      },
    },
  };
</script>

<style lang="scss" scoped>
  $segment-border-color: #e5e8f1;

  .page-title {
    display: inline-block;
    padding: 40px 0 0 40px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36.5px;
    color: $main-text-color;
  }
</style>
<style lang="scss">
  @import 'shop-player-v2/dist/style';

  .page-container.with-player {
    @import 'src/style/shop-player-extra';
  }
</style>